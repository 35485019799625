import React, { useEffect } from "react";
import { graphql } from "gatsby";
import SiteMetadata from "../components/site-metadata";
import TextPageLayout from "../components/text-page-layout";
import { useIntl } from "../hooks/useIntl";
import styles from "./MarkdownPage.module.scss";

export const query = graphql`
  query (
    $slug: String!,
    $lang: String!
  ) {
    markdownRemark (
      fields: {
        slug: {
          eq: $slug
        }
        lang: {
          eq: $lang
        }
      }
    ) {
      frontmatter {
        title
        description
      }
      html
    }
  }
`;

const MarkdownPage = ({
  data: {
    markdownRemark: {
      frontmatter,
      html,
    },
  },
  location,
  pageContext: {
    lang: pageLang,
    pageLangVariants,
  },
}) => {
  const { lang, setLang, setPageLangVariants } = useIntl();

  useEffect(() => {
    if (lang !== pageLang) {
      setLang(pageLang);
    }

    setPageLangVariants(pageLangVariants);
  }, [lang, pageLang, setLang, setPageLangVariants, pageLangVariants]);

  return (
    <TextPageLayout>
      <SiteMetadata
        location={location}
        title={frontmatter.title}
        description={frontmatter.description}
      />

      <h1>{frontmatter.title}</h1>

      <div
        className={styles.markdownContent}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </TextPageLayout>
  );
};

export default MarkdownPage;
